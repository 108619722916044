import { css } from '@emotion/react'
import React from 'react'
import { ENGLISH_TITLE } from '../../components/data/English'
import { SEO } from '../../components/data/SEO'
import { CardService } from '../../components/molecules/Card/CardService'
import { LayoutService } from '../../components/organisms/Layout/LayoutService'

const cardList = [
  {
    serviceName: 'BPO事務代行',
    imagePathPc: '/service/img/outsourcing/outsourcing1.jpg',
    imagePathSp: '/service/img/outsourcing/outsourcing1sp.jpg',
    title: 'クライアントの固定費削減・収益性向上に貢献',
    content:
      '日々多忙な総務担当者の業務負担を大幅に軽減し、クライアントの固定費削減・収益性向上に貢献致します。',
    groupList: [
      {
        text: '株式会社キャスティングロード',
        url: 'https://www.cr2.co.jp/service/remote-bpo/',
      },
    ],
    columnNumber: 2,
  },
  {
    serviceName: 'コンタクトセンター',
    imagePathPc: '/service/img/outsourcing/outsourcing2.jpg',
    imagePathSp: '/service/img/outsourcing/outsourcing2sp.jpg',
    title: 'コールセンター派遣で培った実績でトータルサポート',
    content:
      'コールセンター向け派遣20年以上の実績を活かしコールセンターの立ち上げから運用サポート、オペレーター採用までトータルでご支援致します。',
    groupList: [
      {
        text: '株式会社キャスティングロード',
        url: 'https://www.cr2.co.jp/service/cc-outsourcing/',
      },
    ],
    columnNumber: 2,
  },
  {
    serviceName: 'リモートBPO',
    imagePathPc: '/service/img/outsourcing/outsourcing3.jpg',
    imagePathSp: '/service/img/outsourcing/outsourcing3sp.jpg',
    title: 'クライアントの企業価値向上を支援します',
    content:
      '人材派遣で培った日本全国の在宅/フィールドワーカーを活用し、あらゆる課題を遠隔型ローコストBPOサービス「リモートBPO」で解決していきます。',
    groupList: [
      {
        text: '株式会社キャスティングロード',
        url: 'https://www.cr2.co.jp/service/remote-bpo/',
      },
    ],
    columnNumber: 2,
  },
  {
    serviceName: '工場内製造業務',
    imagePathPc: '/service/img/outsourcing/outsourcing4.jpg',
    imagePathSp: '/service/img/outsourcing/outsourcing4sp.jpg',
    title: '高いレベルの「ものづくり」の実現をサポート',
    content:
      '工場内における製造設備の操作や原料・包装資材の供給作業、ライン作業、製品の包装・梱包作業、品質検査など、多岐にわたる業務に対応しています。',
    groupList: [
      {
        text: '株式会社プロテクス',
        url: 'https://crprotex.co.jp/#index_job_description',
      },
      { text: '株式会社ジョブス', url: 'https://jobs-cp.jp/service/' },
    ],
    columnNumber: 2,
  },
  {
    serviceName: '工場内物流業務',
    imagePathPc: '/service/img/outsourcing/outsourcing5.jpg',
    imagePathSp: '/service/img/outsourcing/outsourcing5sp.jpg',
    title: '正確かつスピーディーな対応',
    content:
      '倉庫から運ばれてきた原料や包装資材の入荷・格納、出来上がった商品の工場内搬送と仮保管、出荷先別のトラックへの商品の積み込みなどを行っています。',
    groupList: [
      {
        text: '株式会社プロテクス',
        url: 'https://crprotex.co.jp/#index_job_description',
      },
      { text: '株式会社ジョブス', url: 'https://jobs-cp.jp/service/' },
    ],
    columnNumber: 2,
  },
  {
    serviceName: '倉庫運営',
    imagePathPc: '/service/img/outsourcing/outsourcing6.jpg',
    imagePathSp: '/service/img/outsourcing/outsourcing6sp.jpg',
    title: '倉庫内の効率的なオペレーションを実現',
    content:
      '仕入先から運ばれてくる補完貨物（工場で使用する原料や包装資材）の入出庫、格納、ピッキング、検品作業、在庫管理を一括して請負っているため、製造に必要な原料・資材をジャストインタイムで工場に届けることができます。',
    groupList: [
      {
        text: '株式会社プロテクス',
        url: 'https://crprotex.co.jp/#index_job_description',
      },
      { text: '株式会社ジョブス', url: 'https://jobs-cp.jp/service/' },
    ],
    columnNumber: 2,
  },
  {
    serviceName: '採用支援・採用代行',
    imagePathPc: '/service/img/outsourcing/outsourcing7.jpg',
    imagePathSp: '/service/img/outsourcing/outsourcing7sp.jpg',
    title: '集客から定着までの採用全工程の課題の解決をサポート',
    content:
      '人材ビジネスで培った最適な媒体選定の経験と採用ノウハウを基に専門のコンサルタントが御社の採用業務の課題を解決致します。',
    groupList: [
      {
        text: '株式会社キャスティングロード',
        url: 'https://www.cr2.co.jp/service/hr-assist/',
      },
    ],
    columnNumber: 2,
  },
  {
    serviceName: '営業支援',
    imagePathPc: '/service/img/outsourcing/outsourcing10.jpg',
    imagePathSp: '/service/img/outsourcing/outsourcing10sp.jpg',
    title: 'すべての顧客層に対してのアプローチを可能に',
    content:
      '顧客フェーズに合わせた営業手法による、潜在顧客の発見と見込み顧客育成を仕組み化。コールセンター向けの人材サービス実績を活かした、体制構築と生産性向上を実現します。',
    groupList: [
      {
        text: '株式会社キャスティングロード',
        url: 'https://www.cr2.co.jp/service/sales-assist/',
      },
    ],
    columnNumber: 2,
  },
  {
    serviceName: 'システム開発',
    imagePathPc: '/service/img/outsourcing/outsourcing8.jpg',
    imagePathSp: '/service/img/outsourcing/outsourcing8sp.jpg',
    title: 'Web系システム開発全般に一貫対応',
    content:
      '企画・デザイン・保守まで、Web系システム開発全般を一貫対応します。スマートフォンのアプリ開発を手掛ける技術者も多数在籍しており、スピーディーかつリーズナブルに対応します。',
    groupList: [
      { text: '株式会社CRドットアイ', url: 'https://crdoti.co.jp/service/' },
    ],
    columnNumber: 2,
  },
  {
    serviceName: '出張スキャンサービス',
    imagePathPc: '/service/img/outsourcing/outsourcing9.jpg',
    imagePathSp: '/service/img/outsourcing/outsourcing9sp.jpg',
    title: 'DX推進を支える情報の電子化によりBCP対策を後押し',
    content:
      '各地域での雇用創出、さらには地方創生に資するほか、自治体においてDXが推進される中、情報の電子化・ペーパーレス化を通して業務プロセスの標準化にも貢献していきます。',
    groupList: [
      {
        text: '株式会社キャスティングロード',
        url: 'https://www.cr2.co.jp/service/kokode-scan',
      },
    ],
    columnNumber: 2,
  },
]

const Page = () => {
  return (
    <LayoutService
      heading="アウトソーシング事業"
      headingEnglish={ENGLISH_TITLE.Outsourcing}
      catchCopy="多種多様なニーズに合わせて幅広いシーンをサポート。人材ビジネスの社会的価値観に変革をもたらします。"
    >
      <div className="pc:px-8 bg-white">
        <section className="pc:py-12">
          <ul
            css={css`
              & > li:nth-of-type(3) > div > div.bg-bg10 {
                @media screen and (min-width: 1200px) {
                  height: 104px; //横のボックスと高さ合わせる
                }
              }
            `}
            className="grid gap-x-8 gap-y-12 place-items-center pc:place-items-start pc:grid-cols-2"
          >
            {cardList.map((card, i) => (
              <CardService key={i} {...card} />
            ))}
          </ul>
        </section>
      </div>
    </LayoutService>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEO title="アウトソーシング事業" url="/service/img/outsourcing" />
)
